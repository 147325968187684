<!--/Top Header -->
<header class="header p-4">
  <nav class="navbar navbar-expand-lg header-nav">
    <div class="navbar-header">
      <a id="mobile_btn" href="javascript:void(0);">
        <div class="mobile-menu"><span></span></div>
      </a>
      <a href="javascript:void(0);" id="profile">
        <ul class="nav header-navbar-rhtx">
          <li class="nav-item dropdown logged-item" *ngIf="auth">
            <a
              href="javascript:void(0)"
              class="dropdown-toggle nav-link"
              data-toggle="dropdown"
            >
              <span class="user-img">
                <img
                  *ngIf="userimage"
                  class="user-image"
                  src="{{ userimage }}"
                  width="31"
                  alt="{{ usernamesurname }}"
                />
                <img
                  *ngIf="!userimage"
                  class="user-image"
                  src="assets/img/user_avatar.jpg"
                  width="31"
                  alt="{{ usernamesurname }}"
                />
              </span>
            </a>
            <div
              class="dropdown-menu dropdown-menu-right"
              style="right: 0; left: auto"
            >
              <div class="user-header">
                <div class="avatar avatar-sm">
                  <img
                    *ngIf="userimage"
                    src="{{ userimage }}"
                    alt="User Image"
                    class="avatar-img user-image"
                  />
                  <img
                    *ngIf="!userimage"
                    src="assets/img/user_avatar.jpg"
                    alt="User Image"
                    class="avatar-img user-image"
                  />
                </div>

                <div class="user-text">
                  <h6>{{ usernamesurname }}</h6>
                  <p class="text-muted mb-0">{{ userrole }}</p>
                </div>
              </div>
              <a
                class="dropdown-item"
                *ngIf="isDoctor"
                [routerLink]="'/doctor/dashboard'"
                style="cursor: pointer"
                >{{ "User Part Dashboard" | translate }}</a
              >
              <a
                class="dropdown-item"
                *ngIf="isDoctor"
                [routerLink]="'/doctor/settings'"
                style="cursor: pointer"
                >{{ "User Part Settings" | translate }}</a
              >
              <a
                class="dropdown-item"
                *ngIf="isPatient"
                [routerLink]="'/patients/dashboard'"
                style="cursor: pointer"
                >{{ "User Part Dashboard" | translate }}</a
              >
              <a
                class="dropdown-item"
                *ngIf="isPatient"
                [routerLink]="'/patients/settings'"
                style="cursor: pointer"
                >{{ "User Part Settings" | translate }}</a
              >

              <a
                class="dropdown-item"
                *ngIf="isAdmin"
                [routerLink]="'/admin/dashboard'"
                style="cursor: pointer"
                >{{ "User Part Dashboard" | translate }}</a
              >
              <a
                class="dropdown-item"
                *ngIf="isAdmin"
                [routerLink]="'/admin/settings'"
                style="cursor: pointer"
                >{{
                  "User
                Part Settings" | translate
                }}</a
              >

              <a
                class="dropdown-item"
                *ngIf="isHospitalAdmin"
                [routerLink]="'/hospital-admin/dashboard'"
                style="cursor: pointer"
                >{{ "User Part Dashboard" | translate }}</a
              >
              <a
                class="dropdown-item"
                *ngIf="isHospitalAdmin"
                [routerLink]="'/hospital-admin/settings'"
                style="cursor: pointer"
                >{{ "User Part Settings" | translate }}</a
              >

              <a
                class="dropdown-item"
                *ngIf="isThyAdmin"
                [routerLink]="'/thy-admin/dashboard'"
                style="cursor: pointer"
                >{{ "User Part Dashboard" | translate }}</a
              >

              <a
                class="dropdown-item"
                (click)="logout()"
                style="cursor: pointer"
                >{{ "User Part Logout" | translate }}</a
              >
            </div>
          </li>
        </ul>
      </a>
      <a
        href="javascript:void(0);"
        id="translate-language"
        *ngIf="languageAreas"
      >
        <div class="position-relative pt-3">
          <ul (click)="openFlag = !openFlag">
            <li [ngClass]="seletOptionLanguage === 'tr' ? 'd-flex' : 'd-none'">
              <img
                src="/assets/img/flag-icons/trflag.svg"
                alt="turkiye-flag"
                class="flag-images"
              />
            </li>
            <li [ngClass]="seletOptionLanguage === 'en' ? 'd-flex' : 'd-none'">
              <img
                src="/assets/img/flag-icons/usaflag.svg"
                alt="usa-flag"
                class="flag-images"
              />
            </li>
          </ul>
          <ul class="list-group language" *ngIf="openFlag">
            <li
              (click)="onChangeLanguage('en')"
              class="list-group-item language d-flex justify-content-center w-100"
            >
              <img
                src="/assets/img/flag-icons/usaflag.svg"
                alt="usa-flag"
                class="flag-images"
              />
            </li>
            <li
              (click)="onChangeLanguage('tr')"
              class="list-group-item language d-flex justify-content-center w-100"
            >
              <img
                src="/assets/img/flag-icons/trflag.svg"
                alt="turk-flag"
                class="flag-images"
              />
            </li>
          </ul>
        </div>
        <!-- <div class="nav-lang-select">
          <select #langselect (change)="onChangeLanguage(langselect.value)" [(ngModel)]="seletOptionLanguage"
            style="font-weight: 500;text-align: -webkit-center;">
            <option *ngFor="let lang of allLangs" [value]="lang.code">{{lang.title}}</option>
          </select>
        </div> -->
      </a>
      <a href="javascript:void(0);" (click)="home()" class="navbar-brand logo">
        <img src="assets/img/logo.png" class="img-fluid" alt="Logo" />
      </a>
    </div>
    <div class="main-menu-wrapper">
      <div class="menu-header">
        <!-- <a class="menu-logo" href="javascript:void(0);">
          <img src="assets/img/logo.png" class="img-fluid" alt="Logo">
        </a> -->
        <div class="w-100 text-right">
          <a id="menu_close" class="menu-close" href="javascript:void(0);">
            <i class="fas fa-times"></i>
          </a>
        </div>
      </div>
      <ul class="main-nav">
        <li class="has-submenu">
          <a href="javascript:void(0)" class="top-menu"
            >{{ "Solutions" | translate }}
          </a>
          <ul class="submenu" id="patients">
            <li>
              <a href="javascript:void(0);" [routerLink]="'/solutions'"
                >VERADoctors</a
              >
            </li>
            <li>
              <a
                href="javascript:void(0);"
                [routerLink]="'/solutions/telehealth'"
                >VERATeleHealth</a
              >
            </li>
            <li>
              <a
                href="javascript:void(0);"
                [routerLink]="'/solutions/monitoring'"
                >VERAMonitoring</a
              >
            </li>
            <li>
              <a
                href="javascript:void(0);"
                [routerLink]="'/solutions/hospitals'"
                >VERAHospitals</a
              >
            </li>
            <li>
              <a href="javascript:void(0);" [routerLink]="'/solutions/edu'"
                >VERAMasterClass</a
              >
            </li>
          </ul>
        </li>

        <li class="has-submenu">
          <a href="javascript:void(0)" class="top-menu"
            >{{ "Patients" | translate }}
          </a>
          <ul class="submenu" id="patients">
            <li>
              <a href="javascript:void(0);" [routerLink]="'/how-it-works'">{{
                "How it Works" | translate
              }}</a>
            </li>
            <li>
              <a href="javascript:void(0);" [routerLink]="'/what-we-treat'">{{
                "What we Treat" | translate
              }}</a>
            </li>
            <li>
              <a href="javascript:void(0);" [routerLink]="'/membership'">{{
                "Memberships" | translate
              }}</a>
            </li>
            <li>
              <a
                href="javascript:void(0);"
                [routerLink]="'/work-in-progress'"
                >{{ "Testimonials" | translate }}</a
              >
            </li>
            <li>
              <a href="javascript:void(0);" [routerLink]="'/our-providers'">{{
                "WHAT do we Provide?" | translate
              }}</a>
            </li>
            <li>
              <a
                href="javascript:void(0);"
                [routerLink]="'/frequently-asked-questions'"
                >{{
                  "Frequently Asked
                Questions" | translate
                }}</a
              >
            </li>
            <li>
              <a href="javascript:void(0);" [routerLink]="'/covid'">Covid-19</a>
            </li>
          </ul>
        </li>

        <li class="has-submenu">
          <a href="javascript:void(0)" class="top-menu"
            >{{ "Services(Our)" | translate }}
          </a>
          <ul class="submenu" id="legal">
            <li *ngFor="let hospitalSlug of hospitalSlugs">
              <a [routerLink]="['/hospital-page', hospitalSlug?.slug]">
                {{ hospitalSlug?.name }}
              </a>
            </li>
          </ul>
        </li>

        <!-- <li class="has-submenu">
          <a href="javascript:void(0)" class="top-menu"
            >{{ "Businesses" | translate }}
          </a>
          <ul class="submenu" id="businesses">
            <li>
              <a
                href="javascript:void(0);"
                [routerLink]="'/transport-companies'"
                >{{
                  "Airlines, Cruise Ships, and
                private transport companies" | translate
                }}</a
              >
            </li>
            <li>
              <a
                href="javascript:void(0);"
                [routerLink]="'/hotels-rentals-tours'"
                >{{
                  "Hotels, Private Rentals and Tour
                Operators" | translate
                }}</a
              >
            </li>
            <li>
              <a
                href="javascript:void(0);"
                [routerLink]="'/hospital-and-private-clinics'"
                >{{
                  "Hospital and Private
                Clinics" | translate
                }}</a
              >
            </li>
            <li>
              <a href="javascript:void(0);" [routerLink]="'/pharmacies-labs'">{{
                "Pharmacies and Labs" | translate
              }}</a>
            </li>
            <li>
              <a
                href="javascript:void(0);"
                [routerLink]="'/work-in-progress'"
                >{{ "Insurance Companies" | translate }}</a
              >
            </li>
            <li>
              <a href="javascript:void(0);" [routerLink]="'/government'">{{
                "Government" | translate
              }}</a>
            </li>
            <li>
              <a
                href="javascript:void(0);"
                [routerLink]="'/become-a-provider'"
                >{{ "Become a provider" | translate }}</a
              >
            </li>
          </ul>
        </li> -->

        <li class="has-submenu">
          <a href="javascript:void(0)" class="top-menu"
            >{{ "Travel" | translate }}
          </a>
          <ul class="submenu" id="legal">
            <li>
              <a
                href="javascript:void(0);"
                [routerLink]="'/travel-with-VERADoctors'"
                >VERADoctors {{ "Travel" | translate }}</a
              >
            </li>
          </ul>
        </li>

        <li>
          <a
            href="javascript:void(0)"
            [routerLink]="'/membership'"
            class="top-menu"
            >{{ "Membership" | translate }}</a
          >
        </li>
        <li>
          <a
            href="javascript:void(0)"
            [routerLink]="'/kvkk'"
            class="top-menu"
            >{{ "KVKK" | translate }}</a
          >
        </li>

        <li
          *ngIf="isAdmin"
          class="has-submenu"
          (click)="addStyle('admin')"
          [class.active]="base === 'admin'"
        >
          <a href="javascript:void(0)" class="top-menuw"
            >{{ "Admin" | translate }} <i class="fas fa-chevron-down"></i
          ></a>
          <ul class="submenu" id="admin">
            <li [class.active]="base === 'admin'">
              <a (click)="navigate('Admin')">{{
                "Admin Dashboard" | translate
              }}</a>
            </li>
          </ul>
        </li>

        <!-- <li
          *ngIf="isHospitalAdmin"
          class="has-submenu"
          (click)="addStyle('hospital-admin')"
          [class.active]="base === 'hospital-admin'"
        >
          <a href="javascript:void(0)" class="top-menuw"
            >{{ "Hospital Admin" | translate }}
            <i class="fas fa-chevron-down"></i
          ></a>
          <ul class="submenu" id="hospital-admin">
            <li [class.active]="base === 'hospital-admin'">
              <a (click)="navigate('Hospital Admin')">{{
                "Hospital Admin
                Dashboard" | translate
              }}</a>
            </li>
          </ul>
        </li> -->

        <li
          *ngIf="isThyAdmin"
          class="has-submenu"
          (click)="addStyle('thy-admin')"
          [class.active]="base === 'thy-admin'"
        >
          <a
            href="javascript:void(0)"
            class="top-menuw"
            (click)="navigate('Thy Admin')"
            >{{ "Dashboard" | translate }}</a
          >
          <!-- <ul class="submenu" id="thy-admin">
            <li [class.active]="base === 'hospital-admin'"><a
                (click)="navigate('Thy Admin')">Thy Admin Dashboard</a></li>
          </ul> -->
        </li>

        <li class="login-link">
          <a [routerLink]="'/patients/call-patient'" class="top-menuw">{{
            "Call Center" | translate
          }}</a>
        </li>
        <li class="login-link">
          <a [routerLink]="'login-page'" class="top-menuw">{{
            "Login / Signup" | translate
          }}</a>
        </li>
      </ul>
      <div class="w-100 smallLanguageContainer">
        <div id="selectlanguageSmallScreen" class="position-relative">
          <ul (click)="openFlag = !openFlag" class="cursor-pointer">
            <li [ngClass]="seletOptionLanguage === 'tr' ? 'd-flex' : 'd-none'">
              <img
                src="/assets/img/flag-icons/trflag.svg"
                alt="turkiye-flag"
                class="flag-images"
              />
            </li>
            <li [ngClass]="seletOptionLanguage === 'en' ? 'd-flex' : 'd-none'">
              <img
                src="/assets/img/flag-icons/usaflag.svg"
                alt="usa-flag"
                class="flag-images"
              />
            </li>
          </ul>
          <ul class="list-group language" *ngIf="openFlag">
            <li
              (click)="onChangeLanguage('en')"
              class="list-group-item language d-flex justify-content-center w-100"
            >
              <img
                src="/assets/img/flag-icons/usaflag.svg"
                alt="usa-flag"
                class="flag-images"
              />
            </li>
            <li
              (click)="onChangeLanguage('tr')"
              class="list-group-item language d-flex justify-content-center w-100"
            >
              <img
                src="/assets/img/flag-icons/trflag.svg"
                alt="turk-flag"
                class="flag-images"
              />
            </li>
          </ul>
        </div>
      </div>
    </div>
    <ul class="nav header-navbar-rht">
      <li
        class="nav-item dropdown dropdown-toggle nav-link text-center"
        id="callcenter"
        *ngIf="isPatient"
        [routerLink]="'/patients/call-patient'"
      >
        <span class="user-img" id="call-center-id">
          {{ "Call Center" | translate }}
        </span>
      </li>

      <li id="selectlanguage" class="position-relative">
        <ul (click)="openFlag = !openFlag" class="cursor-pointer">
          <li [ngClass]="seletOptionLanguage === 'tr' ? 'd-flex' : 'd-none'">
            <img
              src="/assets/img/flag-icons/trflag.svg"
              alt="turkiye-flag"
              class="flag-images"
            />
          </li>
          <li [ngClass]="seletOptionLanguage === 'en' ? 'd-flex' : 'd-none'">
            <img
              src="/assets/img/flag-icons/usaflag.svg"
              alt="usa-flag"
              class="flag-images"
            />
          </li>
        </ul>
        <ul class="list-group language" *ngIf="openFlag">
          <li
            (click)="onChangeLanguage('en')"
            class="list-group-item language d-flex justify-content-center w-100"
          >
            <img
              src="/assets/img/flag-icons/usaflag.svg"
              alt="usa-flag"
              class="flag-images"
            />
          </li>
          <li
            (click)="onChangeLanguage('tr')"
            class="list-group-item language d-flex justify-content-center w-100"
          >
            <img
              src="/assets/img/flag-icons/trflag.svg"
              alt="turk-flag"
              class="flag-images"
            />
          </li>
        </ul>
      </li>
      <!-- <li id="selectlanguage">
        <div class="nav-lang-select">
          <select #langselect (change)="onChangeLanguage(langselect.value)" [(ngModel)]="seletOptionLanguage"
            style="font-weight: 500; text-align: -webkit-center;">
            <option *ngFor="let lang of allLangs" [value]="lang.code">{{lang.title}}</option>
          </select>
        </div>
      </li> -->
      <!-- <li [class.active]="base === 'login-page'" class="nav-item" *ngIf="!auth">
        <a class="nav-link header-login"><span [routerLink]="'login-page'"><a href="javascript:void(0);" id="span1"
              style="font-weight: 500"> {{"Log in" | translate}} </a></span><span id="span2">|</span><span
            [routerLink]="'Register'"> <a href="javascript:void(0);" id="span3" style="font-weight: 500">
              {{"Register" | translate}} </a></span></a>
      </li> -->
      <li [class.active]="base === 'login-page'" class="nav-item" *ngIf="!auth">
        <a
          href="#"
          class="nav-item nav-link loginText"
          [routerLink]="'login-page'"
          ><img
            class="w-50"
            src="/assets/img/img/svg/logoutvera.svg"
            alt="login"
          />
          {{ "Login" | translate }}</a
        >
        <button class="btnRegister" [routerLink]="'Register'">
          {{ "Register" | translate }}
        </button>
      </li>

      <!-- User Menu -->
      <li class="nav-item dropdown logged-item" *ngIf="auth">
        <a
          href="javascript:void(0)"
          class="dropdown-toggle nav-link"
          data-toggle="dropdown"
        >
          <span class="user-img">
            <img
              *ngIf="userimage"
              class="user-image"
              src="{{ userimage }}"
              width="31"
              alt="{{ usernamesurname }}"
            />
            <img
              *ngIf="!userimage"
              class="user-image"
              src="assets/img/user_avatar.jpg"
              width="31"
              alt="{{ usernamesurname }}"
            />
          </span>
        </a>
        <div
          class="dropdown-menu dropdown-menu-right"
          style="right: 0; left: auto"
        >
          <div class="user-header">
            <div class="avatar avatar-sm">
              <img
                *ngIf="userimage"
                src="{{ userimage }}"
                alt="User Image"
                class="avatar-img user-image"
              />
              <img
                *ngIf="!userimage"
                src="assets/img/user_avatar.jpg"
                alt="User Image"
                class="avatar-img user-image"
              />
            </div>

            <div class="user-text">
              <!--<h6>{{isPatient ? 'Richard Wilson' : 'Darren Elder' }}</h6>
                                <p class="text-muted mb-0" *ngIf="!isPatient">Doctor</p>
                                <p class="text-muted mb-0" *ngIf="isPatient">Patient</p>-->

              <h6>{{ usernamesurname }}</h6>

              <p class="text-muted mb-0">{{ userrole | translate }}</p>
            </div>
          </div>

          <a
            class="dropdown-item"
            *ngIf="isDoctorCandidate"
            [routerLink]="'/doctor/settings'"
            style="cursor: pointer"
            >{{
              "User
            Part Settings" | translate
            }}</a
          >
          <a
            class="dropdown-item"
            *ngIf="isDoctor"
            [routerLink]="'/doctor/dashboard'"
            style="cursor: pointer"
            >{{
              "User
            Part Dashboard" | translate
            }}</a
          >
          <a
            class="dropdown-item"
            *ngIf="isDoctor"
            [routerLink]="'/doctor/settings'"
            style="cursor: pointer"
            >{{
              "User
            Part Settings" | translate
            }}</a
          >
          <a
            class="dropdown-item"
            *ngIf="isPatient"
            [routerLink]="'/patients/dashboard'"
            style="cursor: pointer"
            >{{ "Dashboard" | translate }}</a
          >
          <a
            class="dropdown-item"
            *ngIf="isPatient"
            [routerLink]="'/patients/settings'"
            style="cursor: pointer"
            >{{ "User Part Settings" | translate }}</a
          >

          <a
            class="dropdown-item"
            *ngIf="isAdmin"
            [routerLink]="'/admin/dashboard'"
            style="cursor: pointer"
            >{{
              "User
            Part Dashboard" | translate
            }}</a
          >
          <a
            class="dropdown-item"
            *ngIf="isAdmin"
            [routerLink]="'/admin/settings'"
            style="cursor: pointer"
            >{{
              "User
            Part Settings" | translate
            }}</a
          >

          <a
            class="dropdown-item"
            *ngIf="isHospitalAdmin"
            [routerLink]="'/hospital-admin/dashboard'"
            style="cursor: pointer"
            >{{ "User Part Dashboard" | translate }}</a
          >
          <a
            class="dropdown-item"
            *ngIf="isHospitalAdmin"
            [routerLink]="'/hospital-admin/settings'"
            style="cursor: pointer"
            >{{ "User Part Settings" | translate }}</a
          >

          <a
            class="dropdown-item"
            *ngIf="isCallCenter"
            [routerLink]="'/call-center/dashboard'"
            style="cursor: pointer"
            >{{ "Dashboard" | translate }}</a
          >

          <a
            class="dropdown-item"
            *ngIf="isThyAdmin"
            [routerLink]="'/thy-admin/dashboard'"
            style="cursor: pointer"
            >{{ "Dashboard" | translate }}</a
          >
          <a
            class="dropdown-item"
            *ngIf="isThyAdmin"
            [routerLink]="'/thy-admin/visitors'"
            style="cursor: pointer"
            >{{ "Visitors" | translate }}</a
          >

          <a class="dropdown-item" (click)="logout()" style="cursor: pointer">{{
            "User Part Logout" | translate
          }}</a>
        </div>
      </li>

      <!-- /User Menu -->
    </ul>
  </nav>
</header>
